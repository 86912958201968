import { z } from "zod";

export const Memory = z.object({
  content: z.string(),
  createdAt: z.date(),
  id: z.number(),
  updatedAt: z.date(),
});

export type Memory = z.infer<typeof Memory>;
