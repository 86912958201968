import { ArtifactGroup, type ArtifactGroupItem } from "@/components/artifacts/ArtifactGroup.tsx";
import { ARTIFACT_PAGE_SIZE, useArtifacts } from "@/hooks/useArtifacts.tsx";
import { type Message } from "@/types/message.ts";
import { useCallback, useEffect, useMemo, useRef } from "react";

export const ArtifactList = ({ message }: { message: Message }) => {
  const { artifactPages, isLoading, size, setSize } = useArtifacts({ message, order: "desc" });
  const topRef = useRef<HTMLDivElement>(null);

  const groupedArtifacts: Map<string, ArtifactGroupItem> = useMemo(() => {
    const groupedArtifacts: Map<string, ArtifactGroupItem> = new Map();
    const artifacts = artifactPages.flat().reverse();
    const artifactSet = new Set();
    for (const artifact of artifacts) {
      let groupKey = "";
      let groupTitle = "";

      if (artifactSet.has(artifact.id)) {
        continue;
      }
      artifactSet.add(artifact.id);

      switch (artifact.type) {
        case "article":
          groupKey = artifact.article?.publisher?.id?.toString() ?? "";
          groupTitle = artifact.article?.publisher?.name ?? "";
          break;
        default:
          groupKey = "Ungrouped";
          groupTitle = "Ungrouped";
          break;
      }

      if (!groupKey) {
        continue;
      }

      let group = groupedArtifacts.get(groupKey);
      if (!group) {
        group = {
          title: groupTitle,
          artifacts: [],
        };
        groupedArtifacts.set(groupKey, group);
      }
      group.artifacts.push(artifact);
    }
    return groupedArtifacts;
  }, [artifactPages]);

  const handleScroll = useCallback(() => {
    const topArtifactOnScreen = topRef.current?.getBoundingClientRect().top >= 0;
    const pageNotFull = document.body.scrollHeight === window.innerHeight;
    // Check if there are more messages to load
    const moreToLoad = !isLoading &&
      artifactPages &&
      artifactPages[size - 1]?.length === ARTIFACT_PAGE_SIZE;
    // If the user is at the bottom of the page or if the page is not full, and there are more messages to load, load more messages
    if (topArtifactOnScreen && (moreToLoad || pageNotFull)) {
      setSize(size + 1);
    }
  }, [artifactPages, size, isLoading, setSize]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [groupedArtifacts, size, isLoading]);

  if (!groupedArtifacts) {
    return null;
  }

  return (
    <div>
      <div ref={topRef} />
      {Array.from(groupedArtifacts.entries()).map((item) => (
        <ArtifactGroup key={item[0]} group={item[1]} message={message} />
      ))}
    </div>
  );
};
