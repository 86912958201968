import { z } from "zod";

export const Visit = z.object({
  lastVisited: z.string(),
  unseenThreadCount: z.number(),
});

export const Visits = z.object({
  news: Visit,
  updates: Visit,
  events: Visit,
});

export type Visit = z.infer<typeof Visit>;
export type Visits = z.infer<typeof Visits>;
