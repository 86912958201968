import { selectedStatusFilterAtom, selectedThreadAtom } from "@/atoms/threadsAtoms.ts";
import { ChatTextarea } from "@/components/messages/ChatTextarea.tsx";
import ThreadList from "@/components/threads/ThreadList.tsx";
import { statuses } from "@/components/threads/util.ts";
import { Separator } from "@/components/ui/separator.tsx";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { ThreadStatus } from "@/types/thread.ts";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useAtom } from "jotai";
import { useCallback } from "react";

export const Threads = () => {
  const [selectedStatus, setSelectedStatus] = useAtom(selectedStatusFilterAtom);
  const [_, setSelectedThread] = useAtom(selectedThreadAtom);
  const { threadPages } = useThreads();

  const handleStatusChange = useCallback(
    (status: string) => {
      setSelectedStatus(ThreadStatus.enum[status]);
      setSelectedThread(threadPages?.[0]?.[0] || null);
    },
    [setSelectedThread, threadPages],
  );

  return (
    <ViewWrapper>
      <div className="flex flex-col gap-2 lg:mt-2 lg:w-[48rem]">
        <div className="flex flex-col gap-4 basis-1/8">
          <h1 className={`text-4xl fond-extrabold`}>Threads</h1>
          <Tabs defaultValue={selectedStatus}>
            <TabsList className="rounded-full">
              {statuses.map((status) => (
                <TabsTrigger
                  key={status.value}
                  value={status.value}
                  onClick={() => handleStatusChange(status.value)}
                  className="rounded-full"
                >
                  {status.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <Separator className="h-[1px] bg-gray-200" />
        </div>
        <div className="flex-grow overflow-y-hidden">
          <ThreadList />
        </div>
        <ChatTextarea className="flex-grow pt-4" onEnterKeyPressed={() => {}} />
      </div>
    </ViewWrapper>
  );
};
export default Threads;
