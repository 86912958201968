import { Contact } from "@/types/contact.ts";
import { Document } from "@/types/sources/document.ts";
import { z } from "zod";

export const Email = z.object({
  attachments: z.array(Document).optional(),
  bcc: z.string().nullable().optional(),
  body: z.string().optional(),
  cc: z.string().nullable().optional(),
  contacts: z.array(Contact).optional(),
  from: z.string().optional(),
  id: z.number().optional(),
  receivedAt: z.string().transform((val) => new Date(val)).optional(),
  subject: z.string().optional(),
  to: z.string().nullable().optional(),
});

export type Email = z.infer<typeof Email>;
