import ReactMarkdown from "markdown-to-jsx";
import { Link } from "wouter";
import { useState } from "react";

export const Markdown = ({
  children,
  options = {},
  ...props
}: {
  children: React.ReactNode;
  options?: any;
}) => {
  const defaultOptions = {
    ...options,
    forceBlock: true,
    overrides: {
      ul: {
        props: {
          className: "list-disc list-inside",
        },
      },
      blockquote: {
        props: {
          className: "border-l-2 border-gray-300 pl-4 italic text-xl font-light my-4 leading-8",
        },
      },
      a: {
        component: MarkdownLink,
      },
    },
  };

  const content = Array.isArray(children) ? children.join("") : String(children || "");

  return (
    <ReactMarkdown options={defaultOptions} {...props}>
      {content}
    </ReactMarkdown>
  );
};

const MarkdownLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const entityItems = href.split(":");
  const entity = entityItems[0];
  const entityId = entityItems[1];

  let appHref = href;
  switch (entity) {
    case "contact":
      appHref = `/contacts/${entityId}`;
      return getLink(appHref, children);

    case "attachment":
      return getAttachmentImage(entityId);

    default:
      return (
        <a href={href} className="underline">
          {children}
        </a>
      );
  }
};

function getLink(appHref: string, children: React.ReactNode) {
  return (
    <Link
      href={appHref}
      className="underline"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </Link>
  );
}

function getAttachmentImage(threadAttachmentId: string) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
    setIsImageError(false);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
    setIsImageError(true);
  };

  const [threadId, attachmentId] = threadAttachmentId.split("-");
  const imageUrl = `/api/a/threads/${threadId}/raw_inline_images/${attachmentId}`;
  const hideImage = isImageError || !isImageLoaded;
  return (
    <img
      src={imageUrl}
      onLoad={handleImageLoad}
      onError={handleImageError}
      className={hideImage ? "hidden" : ""}
    />
  );
}

export default Markdown;
