import { MessageType } from "@/types/message.ts";
import { ThreadType } from "@/types/thread.ts";

export const formatDateForMessageDetail = (date: Date | undefined): string => {
  if (!date) {
    return "unknown";
  }
  const today = new Date();
  if (date.getDate() === today.getDate()) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  } else if (today.getFullYear() === date.getFullYear()) {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const mapThreadTypeToMessageType = (type: ThreadType): MessageType => {
  switch (type) {
    case "news":
      return "article";
    case "updates":
      return "update";
    case "events":
      return "event";
    default:
      return type;
  }
};
