import { selectedStatusFilterAtom } from "@/atoms/threadsAtoms.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarTrigger,
} from "@/components/ui/sidebar.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import axios from "axios";
import { useAtomValue } from "jotai";
import {
  Brain,
  ChevronDown,
  ChevronsLeft,
  Contact,
  Home,
  LogOut,
  Menu,
  MessagesSquare,
  RefreshCcw,
  Settings,
  Soup,
} from "lucide-react";
import React from "react";
import { Link, useLocation } from "wouter";

const NavSidebar = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className, children, ...props }, ref) => {
  const selectedStatusFilter = useAtomValue(selectedStatusFilterAtom);
  const { userContext, mutate } = useCurrentUserContext();
  const { mutate: mutateThreads } = useThreads({
    status: selectedStatusFilter,
  });
  const [, setLocation] = useLocation();
  const navItems = [
    {
      label: "Home",
      href: "/",
      icon: Home,
    },
    {
      label: "Threads",
      href: "/threads",
      icon: MessagesSquare,
    },
    {
      label: "Relationships",
      href: "/relationships",
      icon: Contact,
    },
    {
      label: "Memories",
      href: "/memories",
      icon: Brain,
    },
    {
      label: "Settings",
      href: "/settings",
      icon: Settings,
    },
  ];

  if (userContext?.user.isAdmin) {
    navItems.push({
      label: "Admin",
      href: "/admin",
      icon: Soup,
    });
  }

  const dropdownItems = [
    {
      label: "Incremental Sync",
      icon: RefreshCcw,
      url: "/a/users/force_sync",
    },
    {
      label: "Hard Reset Sync",
      icon: RefreshCcw,
      url: "/a/users/reset_sync",
    },
  ];

  const handleDropdownItemClick = async (item) => {
    await axios.post(item.url, { userId: userContext?.user.id });
    mutateThreads();
  };

  const handleLogout = async () => {
    await axios.post("/a/users/logout", { userId: userContext?.user.id });
    mutate();
    setLocation("/auth");
  };

  return (
    <Sidebar
      className={`px-2 ${className}`}
      collapsible="offcanvas"
      ref={ref}
      {...props}
    >
      <SidebarHeader className="p-0 pt-4 pb-2">
        <SidebarMenu>
          <SidebarMenuItem className="flex flex-row items-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton className="flex flex-row gap-2 items-center">
                  <div className="w-8 h-8">
                    <img
                      src="/favicon.png"
                      alt="logo"
                      className="grayscale hover:grayscale-0 brightness-110 contrast-200"
                    />
                  </div>
                  <div className="text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                    {userContext?.user.contact.name.split(" ")[0]}
                  </div>
                  <ChevronDown />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-2">
                {dropdownItems.map((item) => (
                  <DropdownMenuItem
                    key={item.label}
                    onSelect={() => handleDropdownItemClick(item)}
                  >
                    <div className="flex items-center gap-2">
                      <item.icon />
                      <div>{item.label}</div>
                    </div>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <SidebarTrigger className="w-12 h-12">
              <ChevronsLeft />
            </SidebarTrigger>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup className="p-0">
          <SidebarGroupContent>
            <SidebarMenu className="py-2 gap-3">
              {navItems.map((item) => (
                <SidebarMenuItem key={item.href} className="hover:bg-gray-100 rounded-full">
                  <SidebarMenuButton asChild>
                    <Link
                      href={item.href}
                      className="flex flex-row items-center gap-2"
                    >
                      <item.icon size={16} />
                      <div className="text-sm">{item.label}</div>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
              {children}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup className="mt-auto px-0 mb-4">
          <SidebarGroupContent>
            <SidebarMenu className="py-2 gap-3">
              <SidebarMenuItem className="hover:bg-gray-100 rounded-full">
                <SidebarMenuButton asChild onClick={handleLogout}>
                  <div className="flex flex-row items-center gap-2">
                    <LogOut />
                    <div className="text-sm">Logout</div>
                  </div>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="text-sm text-muted-foreground px-2 pt-2 pb-4">
        <div className="flex flex-row items-center gap-2">
          <div className="w-4 h-4">
            <img
              src="/favicon.png"
              alt="logo"
              className="grayscale hover:grayscale-0 brightness-110 contrast-200"
            />
          </div>
          <div className="text-xs text-muted-foreground">
            Big Basin Labs
          </div>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
});
NavSidebar.displayName = "NavSidebar";

const NavSideBarTrigger = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <SidebarTrigger className="ml-2 lg:ml-11 size-7 lg:h-20">
        <Menu className="size-5" />
      </SidebarTrigger>
    </div>
  );
};
NavSideBarTrigger.displayName = "NavSideBarTrigger";

export { NavSidebar, NavSideBarTrigger };
