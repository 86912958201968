import { EmailArtifactItem } from "@/components/artifacts/EmailArtifactItem.tsx";
import { UpdateArtifactItem } from "@/components/artifacts/UpdateArtifactItem.tsx";
import { type Artifact } from "@/types/artifacts.ts";
import { type Message } from "@/types/message.ts";
import { ArticleArtifactItem } from "./ArticleArtifactItem.tsx";

export type ArtifactGroupItem = {
  title: string;
  artifacts: Artifact[];
};

export type ArtifactGroupParams = {
  message: Message;
  group: ArtifactGroupItem;
};

export const ArtifactGroup = ({ message, group }: ArtifactGroupParams) => {
  return (
    <div>
      {group.title !== "Ungrouped" && (
        <div
          key={`group-${group}`}
          className="flex flex-row items-center gap-2 font-medium underline w-fit decoration-solid"
        >
          <div>{group.title}</div>
        </div>
      )}
      <div>
        {group.artifacts.map((artifact) => {
          const toReturn: React.ReactNode[] = [];
          switch (artifact.type) {
            case "email":
              artifact.email && toReturn.push(
                <EmailArtifactItem key={artifact.id} message={message} artifact={artifact} email={artifact.email} />,
              );
              break;
            case "article":
              artifact.article && toReturn.push(
                <ArticleArtifactItem key={artifact.id} message={message} article={artifact.article} />,
              );
              break;
            case "update":
              artifact.update && toReturn.push(
                <UpdateArtifactItem key={artifact.id} message={message} update={artifact.update} />,
              );
              break;
            default:
              toReturn.push(<div key={artifact.id}>{artifact.type}</div>);
              break;
          }
          return toReturn;
        })}
      </div>
    </div>
  );
};
