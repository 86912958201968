import { axiosPostFetch } from "@/lib/fetchers.ts";
import type { NewMessageReq } from "@/types/chat.ts";
import { Message, MessageType } from "@/types/message.ts";
import useSWRInfinite, { type SWRInfiniteResponse } from "swr/infinite";
import useSWRMutation from "swr/mutation";

export const MESSAGE_PAGE_SIZE = 10;
export const useMessages = ({
  threadId,
  limit = MESSAGE_PAGE_SIZE,
  type,
  order,
}: {
  threadId?: number;
  limit?: number;
  type?: MessageType;
  order?: "asc" | "desc";
}) => {
  const getMessagesUrl = (page: number, previousPageData?: Array<Message>) => {
    if (previousPageData && previousPageData.length < limit) {
      return null;
    }

    if (!threadId) {
      return null;
    }

    let url = `/a/threads/${threadId}/messages`;
    const queryParams = new URLSearchParams();
    if (limit) {
      queryParams.set("limit", limit.toString());
    }
    if (type) {
      queryParams.set("type", type);
    }
    queryParams.set("page", page.toString());
    if (order) {
      queryParams.set("order", order);
    }
    url += `?${queryParams.toString()}`;
    return url;
  };

  const { trigger, isMutating: isSending } = useSWRMutation(
    getMessagesUrl(0),
    axiosPostFetch<NewMessageReq, Message[]>,
  );

  const {
    data,
    isLoading,
    error,
    mutate,
    size,
    setSize,
    isValidating,
  }: SWRInfiniteResponse<Message[]> = useSWRInfinite<Message[]>(
    getMessagesUrl,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: false,
      refreshInterval: isSending ? 0 : 30000,
    },
  );

  let messagePages = data;
  if (data) {
    messagePages = data.map((page) => Message.array().parse(page));
  }

  const sendMessage = async (text: string) => {
    if (!threadId) {
      throw new Error("threadId is required");
    }
    await trigger(
      { text },
      {
        optimisticData: [
          ...(data ?? []),
          {
            id: Math.random(),
            author: { type: "user" },
            content: text,
            isRead: true,
          },
        ],
        rollbackOnError: true,
      },
    );
    await mutate();
  };

  return {
    messagePages,
    mutate,
    isLoading,
    error,
    isSending,
    sendMessage,
    size,
    setSize,
    isValidating,
  };
};
