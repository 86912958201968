export const Error = ({ error }: { error: Error }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-4">
      <div className="prose text-2xl">
        <div className="flex flex-col items-center justify-center">
          <div>Error:</div>
          <div>{error.message}</div>
        </div>
      </div>
      <a
        className="prose text-xl border-2 border-gray-300 rounded-lg p-2"
        href="/"
      >
        Go Home
      </a>
    </div>
  );
};
