import { messageListScrollPositionAtom } from "@/atoms/messagesAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { MessageDaySeparator } from "@/components/messages/MessageDaySeparator.tsx";
import MessageItem from "@/components/messages/MessageItem.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar.tsx";
import { MESSAGE_PAGE_SIZE, useMessages } from "@/hooks/useMessages.tsx";
import { type MessageType } from "@/types/message.ts";
import { useAtom } from "jotai";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

export const MessageList = ({
  threadId,
  type,
  autoScroll = true,
  className,
  ...props
}: {
  threadId?: number;
  type?: MessageType;
  autoScroll?: boolean;
  className?: string;
}) => {
  const dummyRef = useRef<HTMLDivElement>(null);
  const oldClientHeight = useRef(0);
  const [firstRender, setFirstRender] = useState(true);
  const [messageListScrollPosition, setMessageListScrollPosition] = useAtom(
    messageListScrollPositionAtom,
  );
  const {
    messagePages,
    isLoading: isMessagesLoading,
    isSending: isSendingMessage,
    error: messagesError,
    size,
    setSize,
  } = useMessages({ threadId, type });

  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1 &&
      date2 &&
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const messages = useMemo(() => {
    return messagePages?.flat().reverse();
  }, [messagePages]);

  useLayoutEffect(() => {
    if (dummyRef.current) {
      if (firstRender && autoScroll) {
        if (messageListScrollPosition === -1) {
          dummyRef.current.scrollIntoView();
        } else {
          window.scrollTo(0, messageListScrollPosition);
        }
        setFirstRender(false);
      } else {
        const newClientHeight = document.body.scrollHeight;
        const scrollDifference = newClientHeight - oldClientHeight.current;
        if (scrollDifference > 0) {
          window.scrollTo(0, window.scrollY + scrollDifference);
        }
        oldClientHeight.current = newClientHeight;
      }
    }
  }, [messages, dummyRef, oldClientHeight, isSendingMessage]);

  const handleScroll = () => {
    setMessageListScrollPosition(window.scrollY);

    const atTop = window.scrollY === 0;
    const pageNotFull = document.body.scrollHeight === window.innerHeight;
    // Check if there are more messages to load
    const moreToLoad = !isMessagesLoading &&
      messagePages &&
      messagePages[size - 1]?.length === MESSAGE_PAGE_SIZE;
    // If the user is at the bottom of the page or if the page is not full, and there are more messages to load, load more messages
    if (atTop && (moreToLoad || pageNotFull)) {
      oldClientHeight.current = document.body.scrollHeight;
      setSize(size + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [messages, dummyRef, oldClientHeight, isSendingMessage]);

  if (messagesError) {
    return <Error error={messagesError} />;
  }

  if (isMessagesLoading) {
    return <Loader />;
  }

  return (
    <div className={`pb-24 ${className}`} {...props}>
      <div className="flex flex-col gap-2">
        {messages?.map((message, index) => {
          const toReturn: React.ReactNode[] = [];
          if (index == 0 || !isSameDay(message.receivedAt, messages[index - 1].receivedAt)) {
            toReturn.push(<MessageDaySeparator key={`${message.id}-separator`} date={message.receivedAt} />);
          }
          toReturn.push(<MessageItem key={message.id} message={message} />);
          return toReturn;
        })}
        {isSendingMessage && <LoadingMessage />}
      </div>
      <div ref={dummyRef}></div>
    </div>
  );
};

const LoadingMessage = () => {
  return (
    <div className="p-4 rounded-md flex flex-row gap-4">
      <Avatar className="w-6 h-6">
        <AvatarImage src="/ai_avatar.png" />
        <AvatarFallback className="bg-white text-black">AI</AvatarFallback>
      </Avatar>
      <div className="w-full">
        <div className="p-2 rounded-md bg-white">
          <div className="flex items-center gap-2">
            <div className="flex space-x-2">
              <div className="w-2 h-2 rounded-full bg-purple-300 animate-bounce [animation-delay:-0.3s]"></div>
              <div className="w-2 h-2 rounded-full bg-purple-400 animate-bounce [animation-delay:-0.15s]"></div>
              <div className="w-2 h-2 rounded-full bg-purple-500 animate-bounce"></div>
            </div>
            {/* <span className="text-sm text-gray-500"></span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageList;
