import { isNavSidebarOpenAtom } from "@/atoms/appAtoms.ts";
import { ChatTextarea } from "@/components/messages/ChatTextarea.tsx";
import { NavSidebar, NavSideBarTrigger } from "@/components/NavSidebar.tsx";
import { Button } from "@/components/ui/button.tsx";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { useAtom } from "jotai";
import { ChevronLeft } from "lucide-react";
import { useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";
export const ViewWrapper: React.FC<{
  title?: string;
  titleClassName?: string;
  includeBackButton?: boolean;
  onBack?: () => boolean;
  onEnterKeyPressed?: (value: string) => void;
  isSendingMessage?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
}> = ({
  title,
  titleClassName,
  includeBackButton = false,
  onBack,
  onEnterKeyPressed,
  isSendingMessage = false,
  isDisabled = false,
  children,
  className,
  ...props
}) => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useAtom(isNavSidebarOpenAtom);

  const handleSidebarOpenChange = useCallback(
    (open: boolean) => {
      setIsNavSidebarOpen(open);
    },
    [setIsNavSidebarOpen],
  );

  const handleBackButton = () => {
    let goBack = true;
    if (onBack) {
      goBack = onBack();
    }
    if (goBack) {
      window.history.back();
    }
  };
  useHotkeys("escape", handleBackButton);

  return (
    <SidebarProvider
      open={isNavSidebarOpen}
      onOpenChange={handleSidebarOpenChange}
      className={className}
      style={{
        "--sidebar-width": "14.25rem",
      }}
      {...props}
    >
      <NavSidebar />
      <main className="flex flex-col lg:flex-row w-screen">
        <div
          className={`flex-grow lg:h-full 
            transition-all duration-200 ease-linear
            ${isNavSidebarOpen ? "lg:min-w-6" : "lg:min-w-24 lg:mr-auto"}
             `}
        >
          <NavSideBarTrigger className={`${isNavSidebarOpen ? "lg:hidden" : "lg:block"}`} />
        </div>
        <div className={`flex-auto px-4 lg:px-0 lg:min-w-[50rem] lg:max-w-[66.5rem] overflow-clip`}>
          <div
            className={`flex flex-row items-center lg:fixed z-10 lg:h-20 lg:min-w-[50rem] lg:max-w-[66.5rem]
                        bg-gradient-to-b from-white to-transparent from-80%
                        `}
          >
            {includeBackButton && (
              <Button variant="ghost" size="icon" onClick={handleBackButton} className="hidden lg:block">
                <ChevronLeft className="w-6 h-6" />
              </Button>
            )}
            <div className={titleClassName}>{title || ""}</div>
          </div>
          <div className="flex-grow lg:mt-20">
            {children}
          </div>
          <ChatTextarea
            onEnterKeyPressed={onEnterKeyPressed}
            isSendingMessage={isSendingMessage}
            isDisabled={isDisabled}
          />
        </div>
        <div className={`flex-grow basis-0 lg:ml-auto ${isNavSidebarOpen ? "lg:min-w-6" : "lg:min-w-24"}`}></div>
      </main>
    </SidebarProvider>
  );
};
