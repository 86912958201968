import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { useCurrentUserContext } from "@/hooks/useUser.tsx";
import React, { ChangeEvent, KeyboardEvent, TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
interface ChatTextareaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "rows"> {
  initialRows?: number;
  onEnterKeyPressed?: (value: string) => void;
  isSendingMessage?: boolean;
}

export const ChatTextarea: React.FC<ChatTextareaProps> = ({
  className = "",
  initialRows = 1,
  onEnterKeyPressed,
  isDisabled = false,
  isSendingMessage = false,
  placeholder = "Type your message here...",
  defaultValue = "",
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    setValue(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onEnterKeyPressed?.(value);
      setValue("");
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="fixed bottom-0 w-screen py-4 pr-6 lg:w-176
     bg-gradient-to-t from-white to-transparent from-80% flex 
     flex-row items-center">
      <div className="flex-auto">
        <div
          className={`flex flex-row gap-3 p-4 items-center
                    min-w-full lg:w-176 h-16 shadow-lg border border-gray-200 bg-white rounded-full
                    ${className}`}
        >
          <AvatarWithProgress isSendingMessage={isSendingMessage} />
          <Textarea
            disabled={isDisabled}
            className={`resize-none transition-all duration-200 border-none`}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            onInput={handleInputChange}
            ref={textareaRef}
            rows={initialRows}
            value={value}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

const AvatarWithProgress = ({
  isSendingMessage,
}: {
  isSendingMessage: boolean;
}) => {
  const { userContext } = useCurrentUserContext();
  return (
    <div className="relative">
      {isSendingMessage && (
        <div className="absolute inset-0 -m-1">
          <div
            className="w-full h-full rounded-full border-2 border-transparent border-t-emerald-400 animate-spin"
            style={{
              boxShadow: "0 0 8px rgba(52, 211, 153, 0.6)",
            }}
          />
        </div>
      )}
      <Avatar className="relative">
        <AvatarFallback className="bg-slate-500 text-white">
          {userContext?.user.contact.name.charAt(0)}
        </AvatarFallback>
      </Avatar>
    </div>
  );
};
