import Markdown from "@/components/markdown/Markdown.tsx";
import { Button } from "@/components/ui/button.tsx";
import { axiosPostFetch } from "@/lib/fetchers.ts";
import { type Artifact } from "@/types/artifacts.ts";
import { type EmailArtifact } from "@/types/artifacts/email.ts";
import { type Message } from "@/types/message.ts";
import { Clock } from "lucide-react";
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

type EmailPreviewProps = {
  email: EmailArtifact;
};

type CountdownButtonProps = {
  duration: number; // Duration in seconds
  onComplete: () => void;
};

export const CountdownButton = ({
  duration,
  onComplete,
}: CountdownButtonProps) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (!isActive || timeLeft <= 0) return;

    const interval = setInterval(() => {
      setTimeLeft((time) => {
        if (time <= 1) {
          clearInterval(interval);
          onComplete();
          return 0;
        }
        return time - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isActive, timeLeft, onComplete]);

  const handleSendNow = () => {
    setIsActive(false);
    setTimeLeft(0);
    onComplete();
  };

  const handleCancel = () => {
    setIsActive(false);
    setTimeLeft(0);
  };

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${
    seconds
      .toString()
      .padStart(2, "0")
  }`;

  return (
    <div className="flex items-center gap-4">
      <Button
        className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full flex items-center gap-2"
        onClick={handleSendNow}
      >
        <span>Sending in {formattedTime}</span>
        <Clock className="w-4 h-4" />
      </Button>
      <Button variant="ghost" onClick={handleCancel}>
        Cancel Send
      </Button>
    </div>
  );
};

export default CountdownButton;

// Email Preview Component
export const EmailPreview = ({
  email: { from, to, body },
}: EmailPreviewProps) => {
  const md = `
  > ${body}
  
  Sent by ${from} to ${to.join(", ")}
  `;
  return <Markdown>{md}</Markdown>;
};

interface EmailArtifactProps {
  message: Message;
  artifact: Artifact;
  email: EmailArtifact;
}

export const EmailArtifactItem = ({ message, artifact, email }: EmailArtifactProps) => {
  const { mutate } = useSWRConfig();

  const { isMutating, trigger } = useSWRMutation(
    `/a/threads/${message.threadId}/messages/${message.id}/artifacts/${artifact.id}/approve`,
    axiosPostFetch,
  );

  const handleApprove = async () => {
    await trigger();
    await mutate(`/a/threads/${message.threadId}/messages`);
  };

  return (
    <>
      <EmailPreview email={email} />
      {!artifact.approved && !isMutating && <CountdownButton duration={60} onComplete={handleApprove} />}
    </>
  );
};
