import { Contact } from "@/types/contact.ts";
import useSWR from "swr";

export const useContact = (contactId?: string) => {
  if (!contactId) {
    return {
      contact: undefined,
      isLoading: false,
      error: undefined,
    };
  }
  const { data, isLoading, error } = useSWR<Contact>(
    `/a/contacts/${contactId}`,
    {
      refreshInterval: 30000,
    },
  );

  let toReturn = data;
  if (data) {
    toReturn = Contact.parse(data);
  }

  return {
    contact: toReturn,
    isLoading,
    error,
  };
};
