import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { useContact } from "@/hooks/useContacts.tsx";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { Mail, Phone } from "lucide-react";
import { useParams } from "wouter";

export const Contact = () => {
  const params = useParams();
  const { contact, isLoading, error } = useContact(params[0]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const contactAddresses = contact?.contactAddresses?.map((ca) => {
    let icon = Mail;
    switch (ca.type) {
      case "email":
        icon = Mail;
        break;
      case "phone":
        icon = Phone;
        break;
    }
    return {
      ...ca,
      icon: icon,
    };
  });

  return (
    <ViewWrapper title={contact?.name || ""} includeBackButton={true}>
      <Card className="mx-4">
        <CardHeader>
          <CardTitle className="flex flex-row items-center gap-2">
            {contact?.name}
            <Badge className="w-fit">
              {contact?.type
                ? contact?.type?.charAt(0).toUpperCase() +
                  contact?.type?.slice(1)
                : ""}
            </Badge>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-2">
            {contactAddresses?.map((ca) => (
              <div
                key={ca.address}
                className="flex flex-row items-center gap-2"
              >
                <ca.icon className="w-4 h-4" />
                {ca.address}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </ViewWrapper>
  );
};

export default Contact;
