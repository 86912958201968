import { Thread } from "@/types/thread.ts";

export const threadName = (thread: Thread | undefined) => {
  if (!thread) {
    return "Unknown Thread Type";
  }
  return thread.title;
};

export const formatDateForThreadList = (date: Date): string => {
  if (!date) {
    return "";
  }
  const now = new Date();
  if (now.getTime() - date.getTime() < 24 * 60 * 60 * 1000) {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  } else if (now.getFullYear() === date.getFullYear()) {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const statuses = [
  { value: "all", label: "All" },
  { value: "to-review", label: "To Review" },
  { value: "in-progress", label: "In Progress" },
  { value: "snoozed", label: "Snoozed" },
];

export const formatStatus = (status: string) => {
  return statuses.find((s) => s.value === status)?.label || "";
};
