import { categoryScrollPositionAtom } from "@/atoms/messagesAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { MessageList } from "@/components/messages/MessageList.tsx";
import { useMessages } from "@/hooks/useMessages.tsx";
import { useThreadByType } from "@/hooks/useThreads.tsx";
import { cn } from "@/lib/utils.ts";
import type { ThreadType } from "@/types/thread.ts";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useLocation, useRoute } from "wouter";

export const Category = () => {
  const [_location, setLocation] = useLocation();
  const [matchThread, paramsThread] = useRoute("/categories/:category");
  const [_, setCategoryScrollPosition] = useAtom(categoryScrollPositionAtom);
  const { thread, isLoading, error } = useThreadByType({
    type: paramsThread?.category as ThreadType,
  });
  const { isLoading: isMessagesLoading, isSending: isSendingMessage, sendMessage } = useMessages({
    threadId: thread?.id,
  });

  useEffect(() => {
    if (!matchThread || !paramsThread || !paramsThread.category) {
      setLocation("/");
    }
  }, [matchThread, paramsThread, setLocation]);

  const handleBack = () => {
    setCategoryScrollPosition(-1);
    return true;
  };

  if (!matchThread || !paramsThread || !paramsThread.category) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!thread) {
    return <div>Thread not found</div>;
  }

  return (
    <ViewWrapper
      title={thread?.title?.charAt(0).toUpperCase() + thread?.title?.slice(1)}
      includeBackButton={true}
      onBack={handleBack}
      isSendingMessage={isSendingMessage}
      isDisabled={isMessagesLoading}
      onEnterKeyPressed={sendMessage}
    >
      <div className={cn("lg:w-[44rem]")}>
        <MessageList threadId={thread?.id} />
      </div>
    </ViewWrapper>
  );
};

export default Category;
