import { z } from "zod";

export const ThreadStatus = z.enum([
  "archived",
  "in-progress",
  "to-review",
  "completed",
  "snoozed",
]);

export const ThreadType = z.enum(["people", "updates", "events", "news"]);
export const Thread = z.object({
  id: z.number(),
  status: ThreadStatus,
  summary: z.string().nullable(),
  title: z.string(),
  type: ThreadType,
  lastReceivedAt: z.coerce.date(),
  labels: z.array(z.string()).optional(),
  unreadCount: z.number().optional(),
});

export type Thread = z.infer<typeof Thread>;
export type ThreadStatus = z.infer<typeof ThreadStatus>;
export type ThreadType = z.infer<typeof ThreadType>;
