import { Label } from "@/types/label.ts";
import { Thread, ThreadStatus, ThreadType } from "@/types/thread.ts";
import useSWR from "swr";
import useSWRInfinite, { type SWRInfiniteResponse } from "swr/infinite";
import { useLabels } from "./useLabels.tsx";

export const THREAD_PAGE_SIZE = 5;

export const findAndFormatLabel = (labelMapping: Label[], label: string) => {
  const found = labelMapping.find((lm) => lm.id === label);
  const toFilter = ["CATEGORY", "UNREAD", "SENT", "INBOX", "IMPORTANT"];
  const startsWithFilter = ["CATEGORY"];
  if (
    !found ||
    toFilter.includes(found.name) ||
    startsWithFilter.some((prefix) => found.name.startsWith(prefix))
  ) {
    return undefined;
  }

  return {
    name: found.name.toLowerCase().charAt(0).toUpperCase() +
      found.name.toLowerCase().slice(1),
    textColor: found.textColor,
    backgroundColor: found.backgroundColor,
  };
};

export const useThreads = ({
  status,
  type,
  order,
  limit = THREAD_PAGE_SIZE,
}: {
  status?: ThreadStatus;
  type?: ThreadType;
  order?: "asc" | "desc";
  limit?: number;
} = {}) => {
  const getThreadsUrl = (page: number, previousPageData: Array<Thread>) => {
    if (previousPageData && previousPageData.length < limit) {
      return null;
    }
    let url = `/a/threads`;
    const queryParams = new URLSearchParams();
    if (limit) {
      queryParams.set("limit", limit.toString());
    }
    if (status) {
      queryParams.set("status", status);
    }
    if (type) {
      queryParams.set("type", type);
    }
    queryParams.set("page", page.toString());
    if (order) {
      queryParams.set("order", order);
    }
    url += `?${queryParams.toString()}`;
    return url;
  };

  // Get all labels
  const {
    labels,
    isLoading: isLabelsLoading,
    error: labelsError,
  } = useLabels();

  // Get all threads
  const {
    data,
    isLoading,
    error,
    mutate,
    size,
    setSize,
    isValidating,
  }: SWRInfiniteResponse<Array<Thread>> = useSWRInfinite<Array<Thread>>(
    getThreadsUrl,
    {
      refreshInterval: 30000,
    },
  );

  // Handle Loading Labels and Errors on Labels
  let errorToReturn = error;
  if (labelsError) {
    errorToReturn = labelsError;
  }

  let isLoadingToReturn = isLoading;
  if (isLabelsLoading) {
    isLoadingToReturn = true;
  }

  // Put in the formatted labels for each thread
  let toReturn = data;
  if (!isLoadingToReturn && !errorToReturn) {
    toReturn = data?.map((page) => {
      const threads = Thread.array().parse(page);
      return threads.map((thread) => {
        return {
          ...thread,
          formattedLabels: thread.labels?.map((label) => findAndFormatLabel(labels, label)),
        };
      });
    }) ?? [];
  }

  return {
    error: errorToReturn,
    isLoading: isLoadingToReturn,
    mutate,
    threadPages: toReturn,
    isValidating,
    size,
    setSize,
  };
};

export const useThread = ({ threadId }: { threadId: number }) => {
  const url = `/a/threads/${threadId}`;
  const { data: thread, isLoading, error } = useSWR<Thread>(url);
  return {
    isLoading,
    error,
    thread: thread ? Thread.parse(thread) : undefined,
  };
};

export const useThreadByType = ({ type }: { type: ThreadType }) => {
  const url = `/a/category_thread/${type}`;
  const { data: thread, isLoading, error } = useSWR<Thread>(url);
  return {
    isLoading,
    error,
    thread: thread ? Thread.parse(thread) : undefined,
  };
};
