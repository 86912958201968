import { Label } from "@/types/label.ts";
import useSWR from "swr";

export const useLabels = () => {
  const { isLoading, data, error } = useSWR("/a/labels");

  let toReturn: Label[] = [];
  if (!isLoading && data) {
    toReturn = Label.array().parse(data);
  }
  return {
    labels: toReturn,
    isLoading,
    error,
  };
};
