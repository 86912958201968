import { type Artifact, artifact } from "@/types/artifacts.ts";
import { type Message } from "@/types/message.ts";
import useSWRInfinite, { type SWRInfiniteResponse } from "swr/infinite";

export const ARTIFACT_PAGE_SIZE = 10;

export const useArtifacts = ({
  message = undefined,
  order = "asc",
  limit = ARTIFACT_PAGE_SIZE,
}: {
  message?: Message;
  order?: "asc" | "desc";
  limit?: number;
} = {}) => {
  const getArtifactsUrl = (page: number, previousPageData: Array<Artifact>) => {
    if (previousPageData && previousPageData.length < limit) {
      return null;
    }
    if (!message) {
      return null;
    }
    let url = `/a/threads/${message.threadId}/messages/${message.id}/artifacts`;
    const queryParams = new URLSearchParams();
    if (limit) {
      queryParams.set("limit", limit.toString());
    }
    if (order) {
      queryParams.set("order", order);
    }
    if (page) {
      queryParams.set("page", page.toString());
    }
    url += `?${queryParams.toString()}`;
    return url;
  };

  // Get all threads
  const {
    data,
    isLoading,
    error,
    mutate,
    size,
    setSize,
    isValidating,
  }: SWRInfiniteResponse<Array<Artifact>> = useSWRInfinite<Array<Artifact>>(getArtifactsUrl);

  const artifactPages = data ? data.map((page) => artifact.array().parse(page)) : [];

  return {
    error,
    isLoading,
    mutate,
    artifactPages,
    isValidating,
    size,
    setSize,
  };
};
