import { articleArtifact } from "@/types/artifacts/article.ts";
import { emailArtifact } from "@/types/artifacts/email.ts";
import { updateArtifact } from "@/types/artifacts/update.ts";
import { z } from "zod";

export const artifactType = z.enum(["email", "article", "event", "update", "memory"]);

export const artifact = z.object({
  id: z.number(),
  email: emailArtifact.nullable().optional(),
  article: articleArtifact.nullable().optional(),
  update: updateArtifact.nullable().optional(),
  type: artifactType,
  sortDate: z.coerce.date().nullable().optional(),
  approved: z.boolean().nullable().optional(),
});

export type Artifact = z.infer<typeof artifact>;
export type ArtifactType = z.infer<typeof artifactType>;
