import { Badge } from "@/components/ui/badge.tsx";
import { useCallback } from "react";

export const MessageDaySeparator = ({ date }: { date: Date }) => {
  const formatDate = useCallback((date: Date) => {
    const today = new Date();
    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      return "Today";
    }
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });
  }, []);

  return (
    <div className=" flex flex-col items-center justify-center p-4">
      <Badge variant="outline" className="bg-gray-300">
        {formatDate(date)}
      </Badge>
    </div>
  );
};

export default MessageDaySeparator;
