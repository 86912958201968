import { z } from "zod";

export const Label = z.strictObject({
  id: z.string(),
  name: z.string(),
  type: z.enum(["system", "user"]),
  textColor: z.string().optional(),
  backgroundColor: z.string().optional(),
});

export type Label = z.infer<typeof Label>;
