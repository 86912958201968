import { Contact } from "@/types/contact.ts";
import { z } from "zod";

export const articleArtifact = z.object({
  author: Contact.nullable(),
  //content: z.string(),
  date: z.string().transform((date) => {
    return new Date(date);
  })
    .nullable(),
  id: z.number(),
  publisher: Contact.nullable(),
  summary: z.string(),
  title: z.string(),
  url: z.string().nullable(),
  imageUrl: z.string().nullable(),
});

export type ArticleArtifact = z.infer<typeof articleArtifact>;
