export const Loader = () => {
  return (
    <div
      data-testid="loader"
      className="flex items-center justify-center h-screen"
    >
      <div className="w-12 h-12 border-4 border-blue-500 border-solid border-t-transparent rounded-full animate-spin">
      </div>
    </div>
  );
};
