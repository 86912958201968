import { ContactAddress } from "@/types/contact.ts";
import { z } from "zod";

export const updateArtifact = z.object({
  id: z.number(),
  domain: ContactAddress,
  summary: z.string(),
});

export type UpdateArtifact = z.infer<typeof updateArtifact>;
