import { z } from "zod";

// Define the schemas using z.lazy() for circular references
export const ContactAddress: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.number(),
    contactId: z.number(),
    contact: z.lazy(() => Contact).optional(),
    address: z.string(),
    type: z.enum(["email", "phone", "home", "work"]),
  })
);

export const Contact: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.number(),
    name: z.string(),
    type: z.enum(["person", "company", "assistant", "user"]),
    contactAddresses: z.array(ContactAddress).optional(),
  })
);

// Export the types
export type Contact = z.infer<typeof Contact>;
export type ContactAddress = z.infer<typeof ContactAddress>;
