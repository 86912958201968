import { Source } from "@/types/source.ts";
import useSWR from "swr";

export interface UseSourceParams {
  threadId: string;
  messageId: string;
  sourceId: string;
}

export const useSource = ({
  sourceId,
  threadId,
  messageId,
}: UseSourceParams) => {
  const { data, error, isLoading } = useSWR(
    `/a/threads/${threadId}/messages/${messageId}/sources/${sourceId}`,
  );
  let toReturn: Source | null = null;
  if (!isLoading && data) {
    toReturn = Source.parse(data);
  }
  return {
    error: error,
    isLoading: isLoading,
    source: toReturn,
  };
};
