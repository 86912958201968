import { useEffect, useRef, useState } from "react";

interface UseReadStateOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
  initialValue?: boolean;
}

/**
 * Read state tracking hook
 */
export const useReadState = (options: UseReadStateOptions = {}) => {
  const [isRead, setIsRead] = useState(options.initialValue || false);
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      const isVisible = entry.isIntersecting;
      setIsRead(isVisible);

      if (isVisible && !isRead) {
        setIsRead(true);
      }
    }, {
      root: options.root || null,
      rootMargin: options.rootMargin || "0px",
      threshold: options.threshold || 0.25,
    });

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [options.root, options.rootMargin, options.threshold, isRead]);

  return [elementRef, isRead];
};

const BATCH_DELAY = 1000;

/**
 * Create a batch manager
 */
export const createBatchManager = (callback: (ids: number[]) => void) => {
  const state = {
    batchSet: new Set<number>(),
    timeout: undefined as number | undefined,
  };

  const processBatch = () => {
    if (state.batchSet.size === 0) return;

    const ids = Array.from(state.batchSet);
    callback(ids);
    state.batchSet.clear();
  };

  return {
    add: (id: number) => {
      state.batchSet.add(id);
      if (state.timeout) {
        clearTimeout(state.timeout);
      }
      state.timeout = setTimeout(processBatch, BATCH_DELAY);
    },
    cleanup: () => {
      if (state.timeout) {
        clearTimeout(state.timeout);
        processBatch();
      }
    },
  };
};
