import { UserContext } from "@/types/users.ts";
import useSWR from "swr";
import { axiosFetcher } from "@/lib/fetchers.ts";

export const useUserShow = (userId: null | string) => {
  const { data, isLoading } = useSWR<UserContext>(
    userId ? `/a/agents/${userId}` : null,
  );

  return {
    isLoading,
    user: data,
  };
};

export const useCurrentUserContext = () => {
  const { data, isLoading, error, mutate } = useSWR<UserContext>(
    "/a/users/me",
    {
      fetcher: axiosFetcher,
      errorRetryCount: 0,
    },
  );

  return {
    error,
    isLoading,
    mutate,
    userContext: data,
  };
};
