import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import DocDetail from "@/components/sources/DocDetail.tsx";
import EmailDetail from "@/components/sources/EmailDetail.tsx";
import MemoryDetail from "@/components/sources/MemoryDetail.tsx";
import { useSource, type UseSourceParams } from "@/hooks/useSources.tsx";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useParams } from "wouter";

export const Source = () => {
  const sourceParams = useParams<UseSourceParams>();

  const { error, isLoading, source } = useSource(sourceParams);

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading || !source) {
    return <Loader />;
  }

  let title: string;
  let componentBasedOnSourceType: React.ReactNode;
  switch (source.type) {
    case "email":
      title = source.email?.subject || "";
      componentBasedOnSourceType = <EmailDetail source={source} />;
      break;
    case "document":
      title = source.document?.name || "";
      componentBasedOnSourceType = <DocDetail source={source} />;
      break;
    case "memory":
      title = "Memory";
      componentBasedOnSourceType = <MemoryDetail source={source} />;
      break;
    default:
      title = "Unknown";
      componentBasedOnSourceType = null;
  }

  return (
    <ViewWrapper title={title} includeBackButton={true}>
      <div className="mx-4">{componentBasedOnSourceType}</div>
    </ViewWrapper>
  );
};

export default Source;
