import { messageListScrollPositionAtom } from "@/atoms/messagesAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import MessageList from "@/components/messages/MessageList.tsx";
import { threadName } from "@/components/threads/util.ts";
import { useMessages } from "@/hooks/useMessages.tsx";
import { useThread } from "@/hooks/useThreads.tsx";
import { ViewWrapper } from "@/views/ViewWrapper.tsx";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useLocation, useRoute } from "wouter";

export const Thread = () => {
  const [_location, setLocation] = useLocation();
  const [matchThread, paramsThread] = useRoute("/threads/:threadId");
  const threadId = parseInt(paramsThread?.threadId || "");
  const [_, setMessageListScrollPosition] = useAtom(
    messageListScrollPositionAtom,
  );
  const {
    error: threadError,
    isLoading: isThreadLoading,
    thread,
  } = useThread({ threadId });
  const { isLoading, isSending, sendMessage } = useMessages({ threadId });

  useEffect(() => {
    if (!matchThread || !paramsThread || !paramsThread.threadId) {
      setLocation("/");
    }
  }, [matchThread, paramsThread, setLocation]);

  const handleBack = () => {
    setMessageListScrollPosition(-1);
    return true;
  };

  if (!matchThread || !paramsThread || !paramsThread.threadId) {
    return null;
  }

  if (threadError) {
    return <Error error={threadError} />;
  }

  if (isThreadLoading) {
    return <Loader />;
  }

  return (
    <ViewWrapper
      includeBackButton={true}
      onBack={handleBack}
      title={threadName(thread)}
      isSendingMessage={isSending}
      isDisabled={isLoading || isSending}
      onEnterKeyPressed={sendMessage}
    >
      <div className="flex flex-col w-full lg:w-[48rem]">
        <div className="h-full">
          <MessageList threadId={threadId} className="" />
        </div>
      </div>
    </ViewWrapper>
  );
};

export default Thread;
