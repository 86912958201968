// import { ArticleMessage } from "@/types/messages/article";
// import { PersonMessage } from "@/types/messages/person";
// import { StoreMessage } from "@/types/messages/store";
import { artifact } from "@/types/artifacts.ts";
import { Contact } from "@/types/contact.ts";
import { Source } from "@/types/source.ts";
import { z } from "zod";

export const MessageType = z.enum(["article", "people", "update", "event"]);

export const Message = z.strictObject({
  author: Contact.optional(),
  content: z.string(),
  receivedAt: z.coerce.date(),
  id: z.number(),
  // message: z.union([PersonMessage, ArticleMessage, StoreMessage]),
  sources: z.array(Source).optional(),
  threadId: z.number(),
  type: MessageType,
  isRead: z.boolean().optional(),
  labels: z.array(z.string()).optional(),
  artifacts: artifact.array().optional(),
  artifactsIds: z.array(z.number()).optional(),
});

export type Message = z.infer<typeof Message>;
export type MessageType = z.infer<typeof MessageType>;
