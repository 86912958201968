import { selectedStatusFilterAtom, selectedThreadAtom } from "@/atoms/threadsAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import Markdown from "@/components/markdown/Markdown.tsx";
import { formatStatus, threadName } from "@/components/threads/util.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { THREAD_PAGE_SIZE, useThreads } from "@/hooks/useThreads.tsx";
import { Thread, ThreadType } from "@/types/thread.ts";
import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "wouter";

const ThreadList = () => {
  const [selectedStatus, _] = useAtom(selectedStatusFilterAtom);
  const [selectedThread, setSelectedThread] = useAtom(selectedThreadAtom);
  const {
    threadPages,
    isLoading: isThreadsLoading,
    error: threadsError,
    size,
    setSize,
  } = useThreads({ status: selectedStatus, type: ThreadType.enum.people });
  const [_location, setLocation] = useLocation();
  const labeledThreads = useMemo(() => threadPages?.flat(), [threadPages]);

  useHotkeys("j", () => {
    let nextThread = selectedThread || labeledThreads[0];
    for (let i = 0; i < labeledThreads.length; i++) {
      if (labeledThreads[i].id === selectedThread?.id) {
        nextThread = labeledThreads[i + 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(nextThread);
  });

  useHotkeys("k", () => {
    let previousThread = selectedThread || labeledThreads[0];
    for (let i = 0; i < labeledThreads.length; i++) {
      if (labeledThreads[i].id === selectedThread?.id) {
        previousThread = labeledThreads[i - 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(previousThread);
  });

  useHotkeys("enter", () => {
    if (selectedThread) {
      handleClick(selectedThread);
    }
  });

  const handleClick = useCallback(
    (thread: Thread) => {
      setSelectedThread(thread);
      setLocation(`/threads/${thread.id}`);
    },
    [setSelectedThread],
  );

  const formatDateForThreadList = useCallback((date: Date): string => {
    const now = new Date();
    if (now.getTime() - date.getTime() < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (now.getFullYear() === date.getFullYear()) {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    }
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user is at the bottom of the page or if the page is not full
      const atBottom = window.scrollY + window.innerHeight >= document.body.scrollHeight - 100;
      const pageNotFull = document.body.scrollHeight === window.innerHeight;
      // Check if there are more threads to load
      const moreToLoad = !isThreadsLoading &&
        threadPages &&
        threadPages[size - 1]?.length === THREAD_PAGE_SIZE;
      // If the user is at the bottom of the page or if the page is not full, and there are more threads to load, load more threads
      if ((atBottom || pageNotFull) && moreToLoad) {
        console.log("Loading more threads");
        setSize(size + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [threadPages]);

  if (threadsError) {
    return <Error error={threadsError} />;
  }

  if (isThreadsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col ">
        {labeledThreads.map((thread) => (
          <div
            key={thread.id}
            className={`flex flex-col gap-2`}
            onClick={() => handleClick(thread)}
          >
            <div
              className={`flex flex-col gap-2 p-2 rounded-md hover:bg-gray-100 cursor-pointer ${
                thread.id === selectedThread?.id ? "bg-gray-100" : ""
              }`}
            >
              <div className="flex flex-col lg:flex-row lg:gap-2 w-full">
                <h3 className="text-sm font-semibold basis-3/4 text-ellipsis overflow-hidden">
                  {threadName(thread)}
                </h3>
                <div className="flex-none text-sm text-gray-500 mb-2 lg:mb-0 lg:ml-auto">
                  {formatDateForThreadList(thread.lastReceivedAt)}
                </div>
                <div className="text-sm text-gray-500 text-right">
                  <div className="flex flex-row lg:justify-end text-nowrap flex-wrap w-fit lg:flex-nowrap gap-2 text-xs text-gray-400">
                    {thread.status !== selectedStatus &&
                        formatStatus(thread.status) != ""
                      ? (
                        <Badge className="flex-none">
                          {formatStatus(thread.status)}
                        </Badge>
                      )
                      : (
                        ""
                      )}
                    {thread.formattedLabels
                      ?.filter((label) => label)
                      .map((label) => (
                        <Badge
                          key={label.name}
                          className="text-xs text-gray-500"
                          variant="outline"
                          style={{
                            backgroundColor: label.backgroundColor,
                            color: label.textColor,
                          }}
                        >
                          {label.name}
                        </Badge>
                      ))}
                    {thread.unreadCount > 0 && (
                      <Badge
                        className="text-xs text-gray-500"
                        variant="outline"
                      >
                        {thread.unreadCount}
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-sm text-gray-500 overflow-hidden line-clamp-2">
                <Markdown>{thread.summary}</Markdown>
              </div>
            </div>
            <Separator className="h-[1px] bg-gray-200" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThreadList;
