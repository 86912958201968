import Markdown from "@/components/markdown/Markdown.tsx";
import { type ArticleArtifact } from "@/types/artifacts/article.ts";
import { type Message } from "@/types/message.ts";
import { Dot } from "lucide-react";

export const ArticleArtifactItem = ({ message, article }: { message: Message; article: ArticleArtifact }) => {
  return (
    <div className="flex flex-row gap-2 group mt-4 mb-2">
      <div className="basis-full flex flex-col">
        <div className="flex flex-row text-lg items-center font-semibold 
                  underline decoration-solid text-underline-offset-auto
                  text-underline-position-auto mr-2">
          {article.url
            ? (
              <a
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {article.title}
              </a>
            )
            : (
              article.title
            )}
          {!message.isRead && <Dot size={24} className="text-blue-500" />}
        </div>
        <div className="line-clamp-6 overflow-hidden">
          <Markdown>{article.summary}</Markdown>
        </div>
      </div>
      <div className="w-full h-32 lg:w-64 lg:h-52 flex-none border-2 rounded-lg">
        {article.imageUrl && (
          <img
            src={article.imageUrl.replace(/&amp;/g, "&")}
            alt="Article image"
            className="w-full h-full object-cover rounded-xl"
          />
        )}
      </div>
    </div>
  );
};
