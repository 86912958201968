import { selectedThreadAtom } from "@/atoms/threadsAtoms.ts";
import { Error } from "@/components/Error.tsx";
import { Loader } from "@/components/Loader.tsx";
import { formatDateForThreadList } from "@/components/threads/util.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { useThreads } from "@/hooks/useThreads.tsx";
import { Thread, ThreadType } from "@/types/thread.ts";
import { useAtom } from "jotai";
import { AlarmClockPlus } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "wouter";
import { Markdown } from "../markdown/Markdown.tsx";
const ThreadCards = () => {
  const [selectedThread, setSelectedThread] = useAtom(selectedThreadAtom);
  const {
    threadPages,
    isLoading: isThreadsLoading,
    error: threadsError,
  } = useThreads({
    // status: ThreadStatus.enum["to-review"],
    type: ThreadType.enum["people"],
    limit: 6,
  });
  const [_location, setLocation] = useLocation();

  useHotkeys("j", () => {
    let nextThread = selectedThread || filteredThreads[0];
    for (let i = 0; i < filteredThreads.length; i++) {
      if (filteredThreads[i].id === selectedThread?.id) {
        nextThread = filteredThreads[i + 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(nextThread);
  });

  useHotkeys("k", () => {
    let previousThread = selectedThread || filteredThreads[0];
    for (let i = 0; i < filteredThreads.length; i++) {
      if (filteredThreads[i].id === selectedThread?.id) {
        previousThread = filteredThreads[i - 1] || selectedThread;
        break;
      }
    }
    setSelectedThread(previousThread);
  });

  useHotkeys("enter", () => {
    if (selectedThread) {
      handleClick(selectedThread);
    }
  });

  const handleClick = useCallback(
    (thread: Thread) => {
      setSelectedThread(thread);
      setLocation(`/threads/${thread.id}`);
    },
    [setSelectedThread],
  );

  const chooseBackgroundImage = (thread: Thread) => {
    let imageUrl = "/card_backgrounds/";
    switch (thread.type) {
      case "people": {
        const randomNum = (thread.id % 6) + 1;
        imageUrl += `people-${randomNum}.jpg`;
        break;
      }
      default: {
        imageUrl += "home_pool.jpg";
      }
    }
    return imageUrl;
  };

  const filteredThreads = useMemo(() => {
    return threadPages?.flatMap((page) => {
      return page.map((thread) => {
        return {
          ...thread,
          backgroundImage: chooseBackgroundImage(thread),
        };
      });
    });
  }, [threadPages]);

  if (threadsError) {
    return <Error error={threadsError} />;
  }

  if (isThreadsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-row flex-wrap gap-4">
      {filteredThreads.map((thread) => (
        <div
          key={thread.id}
          className={`${selectedThread?.id === thread.id ? "" : "border-none"} 
            flex-1 rounded-2xl bg-white min-w-full lg:w-[20rem] min-h-80 max-h-80 lg:min-w-64 lg:max-w-86`}
        >
          <div
            className="relative p-4 bg-cover bg-center hover:cursor-pointer min-w-full lg:min-w-64 lg:max-w-86 h-full rounded-2xl"
            style={{ backgroundImage: `url(${thread.backgroundImage})` }}
            onClick={() => handleClick(thread)}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-transparent from-20% opacity-80 to-black rounded-lg">
            </div>
            <div className="relative flex flex-col min-h-full gap-4">
              <div className="flex h-6 flex-row items-center">
                {thread.formattedLabels
                  ?.filter((label) => label)
                  .map((label) => (
                    <Badge
                      key={label.name}
                      className="bg-gray-200 text-black"
                      style={{
                        backgroundColor: label.backgroundColor,
                        color: label.textColor,
                      }}
                    >
                      {label.name}
                    </Badge>
                  ))}
                {thread.unreadCount > 0 && (
                  <Badge className="bg-gray-200 text-black">
                    {thread.unreadCount}
                  </Badge>
                )}
                <AlarmClockPlus className="w-4 h-4 text-white ml-auto" />
              </div>
              <div className="mt-auto flex-none flex flex-col text-white">
                <div className="font-bold text-ellipsis overflow-hidden whitespace-nowrap">
                  {thread.title}
                </div>
                <div className="text-xs font-medium leading-none">
                  {formatDateForThreadList(thread.lastReceivedAt)}
                </div>
              </div>
              <div className="text-sm overflow-hidden line-clamp-3 leading-tight text-white">
                <Markdown>{thread.summary}</Markdown>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThreadCards;
