import { z } from "zod";

export const Document = z.object({
  // Using `any` for content because it can be a string or a Buffer and zod doesn't support Buffer on the client side
  content: z.any().optional(),
  contentDisposition: z.string().optional(),
  createdAt: z.coerce.date(),
  id: z.number(),
  name: z.string(),
  size: z.number().optional(),
  type: z.enum(["attachment", "link"]),
  url: z.string().optional(),
});

export type Document = z.infer<typeof Document>;
