import { Document } from "@/types/sources/document.ts";
import { Email } from "@/types/sources/email.ts";
import { Memory } from "@/types/sources/memory.ts";
import { z } from "zod";

export const SourceType = z.enum(["article", "document", "email", "memory"]);

export const Source = z.object({
  document: Document.nullable().optional(),
  email: Email.nullable().optional(),
  id: z.number(),
  memory: Memory.nullable().optional(),
  type: SourceType,
});

export type Source = z.infer<typeof Source>;
