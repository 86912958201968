import { z } from "zod";

export const emailArtifact = z.object({
  bcc: z.string().array().optional(),
  body: z.string().optional(),
  cc: z.string().array().optional(),
  from: z.string(),
  id: z.number().optional(),
  receivedAt: z.coerce.date().optional(),
  subject: z.string().optional(),
  to: z.string().array(),
});

export type EmailArtifact = z.infer<typeof emailArtifact>;
