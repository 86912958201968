import { Link } from "wouter";

export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
      <h1 className="text-4xl font-bold text-gray-500">404 Not Found</h1>
      <Link className="text-blue-500" href="/">Go Home</Link>
    </div>
  );
};
