// import { useSources } from "@/components/hooks/useSources.tsx";
import { Message } from "@/types/message.ts";
import { Source } from "@/types/source.ts";
import { Document } from "@/types/sources/document.ts";
import { Brain, Mail, Paperclip } from "lucide-react";
import React from "react";
import { Link } from "wouter";

interface SourcesListProps {
  message: Message;
}

const SourcesList: React.FC<SourcesListProps> = ({ message }) => {
  const sources = message.sources;

  if (!sources) {
    return null;
  }

  const displaySources = sources.map((source: Source) => {
    let displaySource: any = {
      id: source.id,
    };
    switch (source.type) {
      case "document":
        displaySource = {
          ...displaySource,
          icon: Paperclip,
          name: source.document?.name,
          date: source.document?.createdAt,
        };
        break;
      case "email":
        displaySource = {
          ...displaySource,
          icon: Mail,
          name: source.email?.subject,
          date: source.email?.receivedAt,
          attachments: source.email?.attachments,
          email: source.email,
        };
        break;
      case "memory":
        displaySource = {
          ...displaySource,
          icon: Brain,
          name: source.memory?.content,
          date: source.memory?.createdAt,
        };
        break;
    }

    return displaySource;
  });

  if (displaySources.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 text-black text-xs w-full">
      {displaySources.map((source) => {
        return (
          <div key={source.id} className="flex flex-row items-center gap-2">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <source.icon className="w-4 h-4" />
                <Link
                  href={`/threads/${message.threadId}/messages/${message.id}/sources/${source.id}`}
                >
                  {source.name}
                </Link>
              </div>
              {getRealAttachments(source.attachments).map(
                (attachment: Document) => {
                  const href = `/api/a/threads/${message.threadId}/raw_attachments/${attachment.id}`;
                  return (
                    <div
                      key={attachment.id}
                      className="flex flex-row items-center gap-2 pl-4 pt-1"
                    >
                      <Paperclip className="w-4 h-4" />
                      <a href={href}>
                        {attachment.name}
                      </a>
                    </div>
                  );
                },
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

function getRealAttachments(attachments: Document[]) {
  if (!attachments) return [];
  const result = attachments.filter((attachment) => {
    // if (attachment.contentDisposition === "attachment") return true;

    // If inline or not specified, check the extension
    const extension = attachment.name.toLowerCase().split(".").pop();

    // If no name, not an attachment
    if (!extension) return false;

    // If not an image, it's an attachment
    const knownImageExtensions = ["gif", "png", "jpg", "jpeg"];
    if (!knownImageExtensions.includes(extension)) return true;

    return false;
  });
  return result;
}

export default SourcesList;
