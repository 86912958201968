import Markdown from "@/components/markdown/Markdown.tsx";
import SourcesList from "@/components/sources/SourcesList.tsx";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { type UpdateArtifact } from "@/types/artifacts/update.ts";
import { type Contact, type ContactAddress } from "@/types/contact.ts";
import { type Message } from "@/types/message.ts";
import { Dot } from "lucide-react";

export const UpdateArtifactItem = ({ message, update }: { message: Message; update: UpdateArtifact }) => {
  return (
    <div className="w-full group">
      <Collapsible>
        <div className="flex flex-row">
          <div className="flex flex-row items-center text-lg underline decoration-solid font-semibold">
            <div>{displayName(update.domain.contact, update.domain)}</div>
            {!message.isRead && <Dot className="text-blue-500 size-12" />}
          </div>
        </div>
        <div className="mt-6">
          <Markdown>{update.summary}</Markdown>
        </div>
        <CollapsibleContent className="pt-2">
          <SourcesList message={message} />
        </CollapsibleContent>
        <Separator className="my-12" />
      </Collapsible>
    </div>
  );
};

const displayName = (contact: Contact, contactAddress: ContactAddress | undefined) => {
  if (!contactAddress) {
    return "Unknown Sender";
  }

  const name = contact.name;
  const domainItems = contactAddress.address.split("@")[1]?.split(".");
  let domain = "";
  if (domainItems && domainItems.length > 2) {
    domain = domainItems.at(-2) ?? "";
  } else {
    domain = domainItems?.at(0) ?? "";
  }
  domain = domain.charAt(0).toUpperCase() + domain.slice(1);
  if (!name || name.trim().length === 0) {
    return domain;
  }

  if (name.toLowerCase().includes(domain.toLowerCase())) {
    return domain;
  }

  return name;
};

export default UpdateArtifactItem;
