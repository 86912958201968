import { Loader } from "@/components/Loader.tsx";
import Markdown from "@/components/markdown/Markdown.tsx";
import { useThreadByType } from "@/hooks/useThreads.tsx";
import { useVisits } from "@/hooks/useVisits.tsx";
import { ThreadType } from "@/types/thread.ts";
import { AppWindow, Calendar, Newspaper } from "lucide-react";
import { useCallback } from "react";
import { useLocation } from "wouter";

const ThreadCategoryCard = ({ category }: { category: string }) => {
  const [_, setLocation] = useLocation();
  const {
    thread,
    isLoading: isThreadsLoading,
    error: threadsError,
  } = useThreadByType({ type: category as ThreadType });
  const { visits, updateVisits } = useVisits();

  const handleCategoryClick = useCallback(() => {
    setLocation(`/categories/${category}`);
    updateVisits(category);
  }, [setLocation]);

  if (isThreadsLoading) {
    return <Loader />;
  }
  if (threadsError) {
    return null;
  }

  const summary = thread?.summary;
  let icon = <></>;
  let name = "";
  let unseenCount = 0;
  switch (category) {
    case "news":
      name = "News";
      icon = <Newspaper size={16} />;
      unseenCount = visits?.news.unseenThreadCount ?? 0;
      break;
    case "events":
      name = "Events";
      icon = <Calendar size={16} />;
      unseenCount = visits?.events.unseenThreadCount ?? 0;
      break;
    case "updates":
      name = "Updates";
      icon = <AppWindow size={16} />;
      unseenCount = visits?.updates.unseenThreadCount ?? 0;
      break;
  }

  return (
    <div
      className="flex-1 p-4 hover:cursor-pointer transition-all 
                duration-300 rounded-2xl border lg:min-w-64 lg:max-w-86"
      onClick={handleCategoryClick}
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-row gap-2 items-center font-normal">
          <div>{icon}</div>
          <div className="font-semibold">{name}</div>
          {unseenCount > 0 && (
            <div className="ml-auto relative flex h-3 w-3">
              <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75">
              </div>
              <div className="relative inline-flex rounded-full h-3 w-3 bg-sky-500">
              </div>
            </div>
          )}
        </div>
        <div className="text-sm overflow-hidden line-clamp-2 leading-tight">
          <Markdown>{summary}</Markdown>
        </div>
      </div>
    </div>
  );
};

export default ThreadCategoryCard;
