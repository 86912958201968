import { axiosPostFetch } from "@/lib/fetchers.ts";
import { Visits } from "@/types/visits.ts";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export const useVisits = () => {
  const { trigger, isMutating: isUpdating } = useSWRMutation(
    "/a/visits",
    axiosPostFetch<{ category: string }, Visits>,
  );

  const { data, isLoading, error, mutate } = useSWR<Visits>("/a/visits");

  const updateVisits = async (category: string) => {
    await trigger({ category });
    await mutate();
  };

  let toReturn: Visits | undefined = undefined;
  if (!error && !isLoading && data) {
    toReturn = Visits.parse(data);
  }

  return {
    visits: toReturn,
    isLoading,
    error,
    updateVisits,
    isUpdating,
  };
};
